
$body-color:                    #575C5F;

// Background
$bg-dark:                       #30353E;
$bg-light:                      #F5F2E6;

// Text;
$color-tx-01:                   #1B1E20;
$color-tx-02:                   #575C5F;

// Main
$ui-primary:                    #1052B4;
$ui-secondary:                  #f9f3ed;

$ui-blue:                       #0887BD;
$ui-cyan:                       #7BADD3;
$ui-green:                      #1B5633;
$ui-yellow:                     #AB7744;


// Border
$bd-color:                      #DCDDDE;

// Height & Width
$ht-base:                       56px;
$ht-header:                     90px;

$wd-sidebar:                    256px;


// Fonts
$ff-primary:                    'Nunito', sans-serif;
$ff-secondary:                  'Cardo', serif;

$font-family-base:              $ff-primary;
$font-weight-base:              300;

// Links
$link-decoration:               none;
