@media (max-width: 768px ) {
    .hardware-wrapper {
        margin-bottom: 96px !important;
    }
}

.hardware-wrapper .heading-1-size {
    font-family: 'Cardo';
    font-style: normal;
    font-weight: 700;
    font-size: 92px;
    line-height: 92px;
    text-align: center;
    color: rgba(163, 32, 53, 0.4);
}

.hardware-wrapper .hardware-switch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']) {
    color: #0E0F0F;
    background: transparent;
    border: none;
    outline: none;
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']), .hardware-wrapper .hardware-switch .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .hardware-wrapper .hardware-switch .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border: none;
    outline: none;
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper:first-child {
    margin-right: 22px;
}

@media (max-width: 768px ) {
    .hardware-wrapper .hardware-switch .ant-radio-button-wrapper:first-child {
        margin-right: 0;
    }

    .hardware-wrapper .heading-1-size {
        font-family: 'Cardo';
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        text-align: center;
        color: rgba(163, 32, 53, 0.4);
    }
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper:last-child {
    margin-left: 22px;
}

@media (max-width: 768px ) {
    .hardware-wrapper .hardware-switch .ant-radio-button-wrapper:last-child {
        margin-left: 0;
    }
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .hardware-wrapper .hardware-switch .ant-radio-button-wrapper-checked::before, .hardware-wrapper .hardware-switch .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper {
    width: 100%;
    max-width: 384px;
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Cardo';
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #0E0F0F;
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper:focus-within {
    box-shadow: 0 0 0 transparent;
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper::after {
    position: relative;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 24px;
    background: #e7e9eb;
    content: '';
}

.hardware-wrapper .hardware-switch .ant-radio-button-wrapper.ant-radio-button-wrapper-checked::after {
    background: #1b5633;
}

.hardware-wrapper .slick-list {
    margin-bottom: 41px;

}

@media (max-width: 768px ) {
    .hardware-wrapper .slick-list {
        margin-bottom: 34px;
    }
}

.hardware-wrapper .slick-list .slick-slide {
    padding: 0 20px;

}

@media (max-width: 768px ) {
    .hardware-wrapper .slick-list .slick-slide {
        padding: 0 0;
    }
}

@media (max-width: 768px ) {
    .hardware-wrapper .slick-list .slick-slide .ant-card {
        border-radius: 3px;
    }
}

.hardware-wrapper .slick-list .slick-slide .ant-card .ant-card-body {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 386px;
    padding: 0px;
}

.hardware-wrapper .slick-list .slick-slide .ant-card .ant-card-body .ant-typography {
    display: block;
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;
    padding: 12px 30px;
    color: white;
    text-align: left;
    background: #1b5633;
}

@media (max-width: 768px ) {
    .hardware-wrapper .slick-list .slick-slide .ant-card .ant-card-body .ant-typography {
        padding: 10px 12px;
        font-size: 15px;
    }
}

.hardware-wrapper .slick-list .slick-slide:first-child {
    padding-left: 0;
}

.hardware-wrapper .slick-list .slick-slide:last-child, .hardware-wrapper .slick-list .slick-slide:nth-child(3) {
    padding-right: 0;
}

.hardware-wrapper .slider-button {
    margin-left: auto;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
}

.hardware-wrapper .slider-button svg {
    width: 26px;
    height: 26px;
}

.hardware-wrapper .slider-button:hover, .hardware-wrapper .slider-button:active, .hardware-wrapper .slider-button:focus {
    color: #1b5633;
}
