/*@import 'src/index';*/
 .about-img {
	 width: 100%;
}
 .image-block {
	 position: relative;
}
 .image-block::after {
	 position: absolute;
	 top: 0;
	 right: 0;
	 width: 30%;
	 height: 100%;
	 background-color: rgba(8,135,189,0.2);
	 content: '';
}
 .image-block-two {
	 position: relative;
	 text-align: end;
 }
 .image-block-two::after {
	 position: absolute;
	 top: 0;
	 right: 0;
	 width: 30%;
	 height: 100%;
	 background-color: rgba(8,135,189,0.2);
	 content: '';
}
 @media (max-width: 768px) {
	 .image-block::after {
		 content: none;
	}
}
 @media (max-width: 768px) {
	 .image-block-two::after {
		 content: none;
	}
}
 .about-title {
	 position: absolute;
	 top: -90px;
	 left: 16%;
	 z-index: 100;
	 color: rgba(163,32,53,0.4) !important;
	 font-size: 92px !important;
}
