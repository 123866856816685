.menu-show {
  overflow: hidden;

  .header-menu {
    p:first-child { opacity: 0; }
    p:not(:first-child) { opacity: 1; }
    span { background-color: transparent; }
    span::before {
      width: 20px;
      transform: rotate(45deg);
      transform-origin: left;
      top: -7px;
    }

    span::after {
      transform: rotate(-45deg);
      transform-origin: left;
      bottom: -7px;
    }
  }

  .menu { display: flex; }
}

.menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 700;
  background-color: #A91E2F;
  display: none;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    width: 540px;
  }
}

.menu-body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  .nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    color: #fff;
    font-size: 24px;
    font-family: $ff-secondary;
    padding: 0 25px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      right: 0;
      border-top: 2px solid $color-tx-01;
      display: none;
    }

    + .nav-link { margin-top: 15px; }

    &.active {
      color: $color-tx-01;
      &::before { display: block; }
    }
  }
}

.menu-footer {
  height: 170px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  @include media-breakpoint-up(lg) {
    padding: 0 64px;
    height: 200px;
  }

  a {
    color: #A91E2F;
    font-family: $ff-secondary;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;

    + a {
      margin-top: 35px;
      @include media-breakpoint-up(lg) { margin-top: 50px; }
    }

    &::before,
    &::after {
      content: '';
      height: 2px;
      background-color: #A91E2F;
      border-radius: 2px;
      display: inline-block;
    }

    &::before {
      width: 40px;
      margin-right: 10px;
    }

    &::after {
      width: 12px;
      margin-left: 10px;
    }
  }
}
