
.mobile-slider-wrapper {
    max-width: 100vw;
}

.mobile-slider-wrapper .ant-carousel {
    width: 100%;
}


@media (max-width: 1000px) {
    .technologies-wrapper {
        width: 95% !important;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    .technologies-wrapper {
        margin-bottom: 96px !important;
    }
    .product-types .product-cards.container .cards-wrapper .ant-carousel{
    margin: 0 auto !important;
}
}

.technologies-wrapper h1.ant-typography {
    margin-top: 50px !important;
    margin-bottom: 100px !important;
    font-family: 'Cardo';
    font-weight: 700;
    font-size: 92px;
    line-height: 92px;
    text-align: center;
    color: rgba(163, 32, 53, 0.4);
}

@media (max-width: 768px) {
    .technologies-wrapper h1.ant-typography {
        margin-bottom: 32px !important;
        font-family: 'Cardo';
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        text-align: center;
        color: rgba(163, 32, 53, 0.4);
    }
}
.technologies-wrapper .technology-card {
    min-height: 312px;
    padding: 32px 50px;
}
@media (max-width: 1200px) {
    .technologies-wrapper .technology-card {
        padding: 25px;
    }
}

@media (max-width: 768px) {

    .technologies-wrapper .slick-current.slick-active {
        width: 303px !important;
        margin-right: 20px;
    }

}



.technologies-wrapper .technology-card .ant-card-body {
    padding: 0;
}

.technologies-wrapper .technology-card .ant-card-body .body1 {
    margin-bottom: 8px;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 23px;
    line-height: 36px;
    color: #0E0F0F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.technologies-wrapper .technology-card .ant-card-body .ant-list {
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #353535;
    flex: none;
    order: 1;
    flex-grow: 0;
    opacity: 0.8;
}

@media (max-width: 768px) {
    .technologies-wrapper .technology-card .ant-card-body .body1 {
        margin-bottom: 8px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 36px;
        color: #0E0F0F;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .technologies-wrapper .technology-card .ant-card-body .ant-list {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #353535;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .technologies-wrapper .technology-card .ant-card-body .body2 {
        flex-wrap: nowrap;
    }
}

.technologies-wrapper .technology-card .ant-card-body .ant-list li {
    margin-bottom: 8px;
    padding: 0;
}

.technologies-wrapper .technology-card .ant-card-body .ant-list li .body2 {
    flex-wrap: nowrap;
}

.technologies-wrapper .technology-card .ant-card-body .ant-list li .children-wrapper {
    min-width: 50%;
    margin-left: 5px;
    font-size: 16px;
}

.technologies-wrapper .technology-card .ant-card-body .ant-list li .children-wrapper .ant-typography {
    margin-bottom: 0;
    color: #575c5f;
}

@media (max-width: 768px) {
    .technologies-wrapper .technology-card .technology-card {
        padding: 15px 16px;
    }

    .technologies-wrapper .technology-card .ant-card-body {
        min-height: 366px;
    }

    .technologies-wrapper .technology-card .ant-card-body .ant-row {
        flex-direction: column-reverse;
    }

    .technologies-wrapper .technology-card .ant-card-body .ant-list li {
        margin-bottom: 4px;
    }

    .technologies-wrapper .technology-card .ant-card-body .ant-list li .ant-list-item-meta {
        align-self: baseline;
    }

    .technologies-wrapper .technology-card .ant-card-body .ant-list li .ant-typography {
        word-break: break-all;
    }

    .technologies-wrapper .technology-card .ant-card-body .ant-list li:last-child {
        margin-bottom: 0;
    }

    .technologies-wrapper .technology-card .ant-card-body .ant-image img {
        max-height: 114px;
    }
}

.technologies-wrapper .card-index {
    display: block;
    width: 100%;
    margin-top: 24px;
    text-align: center;
}
