.page-about {
  .section-media {
    + .section-media {
      .media-body {
        padding: 0;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 200px;
          background-color: rgba($ui-blue, .2);
        }
      }
    }
  }
}

.banner-about {
  max-width: 1440px;

  .media {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .media-body {
    flex: 1;
    position: relative;
  }

  .media-right {
    padding: 24px;

    @include media-breakpoint-up(lg) {
      width: 460px;
      padding: 64px;
    }
  }

  .media:first-child {
    .media-body {
      padding: 100px 24px 0;

      @include media-breakpoint-up(lg) {
        padding: 80px 100px 60px;
      }
    }

    h1 {
      font-size: 48px;
      font-family: $ff-secondary;
      color: $color-tx-01;
      letter-spacing: .5px;

      @include media-breakpoint-up(lg) {
        font-size: 56px;
      }
    }

    .media-right {
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        background-color: $ui-cyan;
        color: #fff;
        padding-top: 64px;
      }

      p {
        font-size: 18px;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          font-size: 20px;
        }
      }
    }
  }

  .media:nth-child(2) {
    .media-body {
      img {
        width: 100%;
        height: auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 200px;
        background-color: rgba($ui-blue, .2);
        display: none;

        @include media-breakpoint-up(lg) { display: block; }
      }
    }
  }

  .media:last-child {
    .media-body {
      padding: 48px 24px 24px;
      font-size: 18px;

      @include media-breakpoint-up(lg) {
        padding: 72px 100px;
        font-size: 23px;
      }

      p { margin-bottom: 0; }
    }
  }

  .media:nth-child(2),
  .media:last-child {
    .media-right {
      position: relative;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      a {
        font-family: $ff-secondary;
        font-weight: 700;
        font-size: 15px;
        color: $ui-cyan;
        position: relative;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 1px;

        + a {
          margin-top: 35px;
          @include media-breakpoint-up(lg) { margin-top: 50px; }
        }
      }
    }
  }
}

.card-about {
  background-color: rgba($ui-blue, .03);
  border-color: rgba($ui-blue, .08);
  border-radius: 4px;
  height: 100%;
  
  .card-body { padding: 40px 25px; }

  .card-icon {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($ui-blue, .03);
    border: 1px solid rgba($ui-blue, .08);
    border-radius: 4px;
    margin-bottom: 20px;

    img {
      width: 40px;
      height: auto;
    }
  }

  h6 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;
    color: $color-tx-01;
  }
}

.section-about {
  padding-top: 0;
  padding-bottom: 0;

  .media {
    display: flex;
    position: relative;
  }

  .media-body {
    flex: 1;
    padding: 72px 100px;
    font-size: 23px;

    h4 {
      font-size: 40px;
      font-family: $ff-secondary;
      color: $color-tx-01;
      margin-bottom: 20px;
    }

    p { margin-bottom: 0; }
  }

  .media-right {
    width: 460px;
    padding: 64px;
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    a {
      font-family: $ff-secondary;
      font-weight: 700;
      color: $ui-cyan;
      position: relative;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 1px;

      + a { margin-top: 50px; }

      &::before,
      &::after {
        content: '';
        height: 2px;
        background-color: $ui-cyan;
        border-radius: 2px;
        display: inline-block;
      }

      &::before {
        width: 40px;
        margin-right: 10px;
      }

      &::after {
        width: 12px;
        margin-left: 10px;
      }
    }
  }

  + .section-about {
    .media-body {
      padding: 0;
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 200px;
        background-color: rgba($ui-blue, .2);
      }
    }
  }
}
