.product-types .title-content .title1.mobile {
	 display: none;
}
 .product-types .title-content .ant-row {
	 margin-top: 165px !important;
	 margin-bottom: 165px !important;
}
 .product-types .title-content .ant-row .ant-col h4 {
	 font-family: 'Cardo';
font-weight: 700;
font-size: 40px;
line-height: 46px;
color: #0E0F0F;
}
 .product-types .title-content .ant-row .ant-col .subtitle {
	 max-width: 472px;
	  font-family: 'Nunito';
font-weight: 400;
font-size: 20px;
line-height: 27px;
color: #575C5F;
}
 .product-types .title-content .ant-row .ant-col .subtitle0 {
	 margin-top: 24px;
	 margin-bottom: 24px;

}
 .product-types .title-content .ant-row .ant-col .subtitle1 {
	 margin-bottom: 40px;
}
 .product-types .rent-info.container h1 {
	 position: relative;
	 top: auto;
	 left: auto;
	 max-width: 906px;
	 margin: 0 auto;
	 font-family: 'Cardo';
font-style: normal;
font-weight: 700;
font-size: 92px;
line-height: 120px;
text-align: center;
color: rgba(163, 32, 53, 0.4);
}
 .product-types .rent-info.container .ant-row {
	 margin-top: 100px !important;
	 margin-bottom: 105px !important;
}
 .product-types .rent-info.container .ant-row .ant-col img {
	 max-width: 548px;
	 margin-right: 20px;
}
 .product-types .rent-info.container .ant-row .ant-col .subtitle {
	 max-width: 472px;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 23px;
line-height: 36px;
color: #575C5F;
	 margin-left: 10px;
}
 .product-types .rent-info.container .ant-row .ant-col .subtitle0 {
	 margin-top: 0;
	 margin-bottom: 24px;
}
 .product-types .rent-info.container .ant-row .ant-col .subtitle1 {
	 margin-bottom: 24px;
}
 .product-types .rent-info.container .ant-row .ant-col:after {
	 content: none;
}
 .product-types .product-cards.container .ant-col:nth-child(3n) {
	 padding-right: 0 !important;
}
 .product-types .product-cards.container .ant-col:nth-child(1) {
	 padding-left: 0 !important;
}
 .product-types .product-cards.container .cards-wrapper {
	 max-width: calc(120vw);
}
 .product-types .product-cards.container .cards-wrapper .product-card {
	 background: rgba(8,135,189,0.03);
	 border: 1px solid rgba(8,135,189,0.08);
	 height: 100%;
}
 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body {
	 padding: 40px;
}
 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body .ant-image {
	 margin: 0;
	 padding: 0;
	 width: 100%;
}
 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body .title2 {
	 margin: 40px 0 0;
	 line-height: 136%;
	 font-family: 'Cardo';
font-weight: 700;
font-size: 24px;
line-height: 33px;
color: #0E0F0F;
}
 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body .body2 {
	 line-height: 136%;
}
 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body .body2.size {
	 margin: 8px 0 0;
	 font-family: 'Cardo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
color: rgba(14, 15, 15, 0.5);
}
 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body .body2.description {
	 margin: 24px 0 0;
	 font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 136%;

/* or 24px */

color: #575C5F;
}
  @media (max-width: 1130px) {
	  .image-block::after {
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  right: 0;
		  width: 30%;
		  height: 97%;
		  background-color: rgba(8, 135, 189, 0.2);
		  content: '';
	  }
  }
 @media (max-width: 1113px) {
	  .image-block::after {
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  right: 0;
		  width: 30%;
		  height: 85%;
		  background-color: rgba(8, 135, 189, 0.2);
		  content: '';
	  }
  }
 @media (max-width: 1035px) {
	  .image-block::after {
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  right: 0;
		  width: 30%;
		  height: 80%;
		  background-color: rgba(8, 135, 189, 0.2);
		  content: '';
	  }
  }
 @media (max-width: 992px) {
	  .image-block::after {
		  display: none;
		  content: '';
	  }
  }
 @media (max-width: 768px) {
	 .product-types {
		 max-width: 80%;
	}

	 .product-types .title-content .title1.mobile {
		 display: block;
		 margin-top: 96px;
		 font-family: 'Cardo';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 46px;
color: #0E0F0F;
	}
	  .product-types .title-content .ant-row .ant-col .subtitle {
	 max-width: 100%;
font-size: 18px;
}
	   .product-types .rent-info.container .ant-row .ant-col .subtitle {
	 max-width: 100%;
font-size: 18px;
line-height: 27px;
	 margin-left: 0px;
}
	 .product-types .title-content .ant-row {
		 flex-wrap: wrap !important;
		 margin-top: 40px !important;
		 margin-bottom: 96px !important;
	}
	 .product-types .title-content .ant-row .ant-col {
		 order: 2;
		 margin: 0 auto !important;
	}
	 .product-types .title-content .ant-row .ant-col h4 {
		 display: none;
	}
	 .product-types .title-content .ant-row .ant-col .pointer.hero-link {
		 margin: 0 !important;
	}
	 .product-types .title-content .ant-row .ant-col.image-block {
		 order: 1;
	}
	 .product-types .rent-info.container .ant-row {
		 flex-wrap: wrap !important;
		 margin-top: 32px !important;
		 margin-bottom: 12px !important;
	}
	  .product-types .rent-info.container h1 {
	 position: relative;
	 top: auto;
	 left: auto;
	 max-width: 906px;
	 margin: 0 auto;
font-family: 'Cardo';
font-style: normal;
font-weight: 700;
font-size: 60px;
line-height: 60px;
text-align: center;
color: rgba(163, 32, 53, 0.4);
}
	 .product-types .rent-info.container .ant-row .ant-col {
		 margin: 0 !important;
	}
	 .product-types .product-cards.container .ant-col:nth-child(3n) {
		 padding-right: initial;
	}
	 .product-types .product-cards.container .ant-col:nth-child(1) {
		 padding-right: initial;
	}
	 .product-types .product-cards.container .cards-wrapper {
		 max-width: 100vw;
	}
	 .product-types .product-cards.container .cards-wrapper .product-card {
		 max-width: 313px;
	}
	 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body {
		 padding: 16px;
	}
	 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body .title2 {
		 margin: 24px 0 0;
		 font-family: 'Cardo';
font-weight: 700;
font-size: 24px;
line-height: 33px;
color: #0E0F0F;
	}
	 .product-types .product-cards.container .cards-wrapper .product-card .ant-card-body .body2.description {
		 max-height: 122px;
		 overflow-y: auto;
		 white-space: normal;
	}
}
 .ant-carousel .mobile-slider {
	 max-width: calc(52vw);
	 max-height: 531px;
	 margin: 0 auto;
}
 .ant-carousel .card-index {
	 display: block;
	 width: 100%;
	 max-width: 327px;
	 margin-top: 32px;
	 text-align: center;
}
