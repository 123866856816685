.highlighted-cards.container {
	 margin-top: 253px;
	 margin-bottom: 210px;
}
 .highlighted-cards.container .title1 {
	 margin-bottom: 108px;
	 text-align: center;
	 font-family: 'Cardo';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 46px;
color: #000000;
}
 @media (max-width: 1200px) {
	 .highlighted-cards.container .ant-row .ant-card {
		 margin: 0 auto;
	}
}
 .highlighted-cards.container .ant-row .highlighted-card {
	 display: flex;
	 align-items: center;
	 width: max-content;
	 max-width: 384px;
	 height: 100%;
	 min-height: 352px;
	  background: rgba(8, 135, 189, 0.03);
border: 1px solid rgba(8, 135, 189, 0.08);
}
 .highlighted-cards.container .ant-row .highlighted-card .ant-card-body {
	 padding: 48px 20px;

box-sizing: border-box;
border-radius: 3px;
	 text-align: center;
}
 .highlighted-cards.container .ant-row .highlighted-card .ant-card-body .ant-typography {
	 font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 32px;
text-align: center;
color: rgba(0, 0, 0, 0.85);;
}
 .highlighted-cards.container .ant-row .highlighted-card .ant-card-body .ant-typography span {
	 text-align: center;
}
 .highlighted-cards.container .ant-row .highlighted-card .ant-card-body .ant-typography .highlighted {
	 color: #75ACD3;
}
 @media (max-width:768px ) {
	 .highlighted-cards.container {
		 margin-top: 96px;
		 margin-bottom: 96px;
		 max-width: 90%;
	}
	 .highlighted-cards.container .title1 {
		 margin-bottom: 24px;
	}
	 .highlighted-cards.container .ant-col {
		 max-width: 100vw;
	}
	 .highlighted-cards.container .ant-col .highlighted-card {
		 max-width: 80% !important;
		 min-height: 300px !important;
		 margin: 0 auto !important;
	}
	 .highlighted-cards.container .ant-col .highlighted-card .ant-card-body {
		 padding: 0 20px;
	}
	 .highlighted-cards.container .ant-col .highlighted-card .ant-card-body .ant-typography {
		 font-size: 16px;
	}
	 .highlighted-cards.container .card-index {
		 display: block;
		 width: 100%;
		 margin-top: 24px;
		 text-align: center;
	}
	 	 .highlighted-cards.container .ant-carousel {
		 width: 100%;
			 margin: 0 auto;
	}
		  .highlighted-cards.container .ant-carousel .mobile-slider {
	 max-width: 100% !important;
	 max-height: 531px !important;
	 margin: 0 !important;
}
}
