/*@import (once) 'antd/lib/style/themes/default';*/

/*@layout-body-background: #fff;*/
/*@button-hover: #e7e9eb;*/
/*@headings-font-family: 'Cardo', serif;*/
/*@text-font-family: 'Nunito', sans-serif;*/
/*@font-family: @text-font-family;*/
/*@text-color: #575c5f;*/
/*@title-color: #1b1e20;*/
/*@red-title-color: rgba(163, 32, 53, 0.4);*/
/*@primary-color: #7badd3;*/
/*@layout-header-background: transparent;*/
/*@green-background: #1b5633;*/
/*@green-transparent-background: rgba(27, 86, 51, 0.2);*/
/*@cards-highlighted-text: #0887bd;*/
* {
	 margin: 0;
	 padding: 0;
	 border: 0;
}
 *, *:before, *:after {
	 -moz-box-sizing: border-box;
	 -webkit-box-sizing: border-box;
	 box-sizing: border-box;
}
 :focus, :active {
	 outline: none;
}
 a:focus, a:active {
	 outline: none;
}
 aside, nav, footer, header, section {
	 display: block;
}
 html, body {
	 min-width: 100%;
	 height: 100%;
}
 body {
	 overflow-x: hidden;
	 line-height: 1;
	 -ms-text-size-adjust: 100%;
	 -moz-text-size-adjust: 100%;
	 -webkit-text-size-adjust: 100%;
}
 input, button, textarea {
	 font-family: sans-serif;
}
 input::-ms-clear {
	 display: none;
}
 button {
	 cursor: pointer;
}
 button::-moz-focus-inner {
	 padding: 0;
	 border: 0;
}
 a, a:visited {
	 text-decoration: none;
}
 a:hover {
	 text-decoration: none;
}
 ul li {
	 list-style: none;
}
 h1, h2, h3, h4, h5, h6 {
	 font-weight: inherit;
	 font-size: inherit;
}
 h1, .heading-1-size, h1.ant-typography {
	 font-size: 92px;
	 line-height: 100%;
}
/*// @media (max-width: ) {*/
/*//	 h1, .heading-1-size, h1.ant-typography {*/
/*//		 font-size: 60px;*/
/*//	}*/
/*//}*/
 h2, .heading-2-size, h2.ant-typography {
	 font-size: 80px;
	 line-height: 100%;
}
/*// @media (max-width: ) {*/
/*//	 h2, .heading-2-size, h2.ant-typography {*/
/*//		 font-size: 48px;*/
/*//	}*/
/*//}*/
 h3, .heading-3-size, h3.ant-typography {
	 font-family: 'Cardo';
	font-weight: 700;
font-size: 56px;
line-height: 100%;
}
 h4, .heading-4-size, h4.ant-typography {
	 font-size: 48px;
	 line-height: 110%;
}
 .title1 {
	 font-weight: 700;
	 font-size: 40px;
	 line-height: 116%;
}
 .timeline-text {
	 font-size: 16px;
	 line-height: 158%;
}
 .title2 {
	 font-weight: 700;
	 font-size: 24px;
	 line-height: 136%;
}
 .highlight {
	 font-weight: 600;
	 font-size: 25px;
	 line-height: 138%;
}
 .body1 {
	 font-size: 23px;
	 line-height: 158%;
}
 .body2 {
	 font-size: 18px;
	 line-height: 156%;
}
 .pointer {
	 cursor: pointer;
}
 .white {
	 color: white !important;
}
 .blue {
	 color: #7badd3 !important;
}
 .container {
	 width: 100%;
	 max-width: 1232px;
	 margin: 0 auto;
}
/*// @media (max-width: ) {*/
/*//	 .container {*/
/*//		 max-width: calc(52vw);*/
/*//	}*/
/*//}*/
 .hero-link {
	 position: relative;
	 display: flex;
	 flex-direction: row;
	 gap: 10px;
	 align-items: center;
	 width: fit-content;
	 text-transform: uppercase;
	 transition: all 300ms ease-in;
}
 .hero-link::before, .hero-link::after {
	 position: relative;
	 height: 2px;
	 background-color: white;
	 content: '';
}
 .hero-link::before {
	 position: relative;
	 display: block;
	 width: 40px;
	 height: 2px;
	 background: #fff;
	 opacity: 1;
	 transition: all 300ms ease-in;
	 content: '';
}
 .hero-link:hover::before {
	 width: 12px;
}
 .hero-link::after {
	 position: relative;
	 display: block;
	 width: 12px;
	 height: 2px;
	 background: #fff;
	 opacity: 1;
	 transition: all 300ms ease-in;
	 content: '';
}
 .hero-link:hover::after {
	 width: 40px;
}
 .blue.hero-link::before, .blue.hero-link::after {
	 background-color: #7badd3;
}
