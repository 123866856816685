
.timeline.container {
    margin-top: 252px;
}

@media (max-width: 768px) {
    .timeline.container {
        margin-top: 96px;
    }
}

.timeline.container .slider-button {
    margin-left: auto;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
}

.timeline.container .slider-button svg {
    width: 26px;
    height: 26px;
}

.timeline.container .slider-button:hover, .timeline.container .slider-button:active, .timeline.container .slider-button:focus {
    color: #7badd3;
}

.timeline.container .timeline-title .title-content {
    margin: 0 auto 100px;
}

@media (max-width: 768px) {
    .timeline.container .timeline-title .title-content {
        margin: 0 auto 32px;
    }
}

.timeline.container .timeline-title .title-content h1.heading-1-size {
    max-width: 876px;
    margin: 0 auto;
    font-family: 'Cardo';
    font-style: normal;
    font-weight: 700;
    font-size: 92px;
    line-height: 100%;
    text-align: center;
    color: rgba(163, 32, 53, 0.4);
}

.timeline.container .timeline-title .title-content .body1 {
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 158%;

    /* or 36px */
    text-transform: capitalize;

    color: #575C5F;
}

@media (max-width: 768px) {
    .timeline.container .timeline-title .title-content .body1 {
        margin: 0;
        margin-top: 24px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #575C5F;
    }

    .timeline.container .timeline-title .title-content h1.heading-1-size {
        max-width: 90%;
        text-align: center;
        color: rgba(163, 32, 53, 0.4);
        font-size: 60px;
        line-height: 60px;
    }
}

@media (max-width: 768px) {
    .timeline.container .timeline-wrapper {
        width: 100vw;
        max-height: 235px;
        margin-right: -24px;
        margin-left: -24px;
        overflow: hidden;
    }
}

.timeline.container .timeline-wrapper .slick-slide:first-child {
    padding-left: 54px;
}

.timeline.container .timeline-wrapper .slick-slide {
    position: relative;
    max-width: 520px;
    padding-right: 48px;
    padding-left: 48px;
    cursor: pointer;
}

.timeline.container .timeline-wrapper .slick-slide::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    margin-top: 11px;
    border: 1px solid #7badd3;
    border-radius: 1px;
    opacity: 0.3;
    content: '';
}

.timeline.container .timeline-wrapper .timeline-item {
    position: relative;
    width: 100%;
    max-width: 425px;
}

.timeline.container .timeline-wrapper .timeline-item .timeline-year {
    display: block;
    margin-bottom: 8px;

}

.timeline.container .timeline-wrapper .timeline-item .timeline-year .body2 {
    display: inline;
    font-weight: 600;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #363636;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
}

.timeline.container .timeline-wrapper .timeline-item .timeline-text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #575C5F;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
}

.timeline.container .timeline-wrapper .timeline-item .timeline-year .body2.year {
    margin-right: 3px;
}

.timeline.container .timeline-wrapper .timeline-item::before {
    position: relative;
    display: block;
    margin-bottom: 14px;
    content: url('/src/pages/about/images/timeline/marker.svg');
}

.timeline.container .timeline-controls {
    max-width: 1232px;
    margin: 0 auto;
    margin-top: 116px;
}

@media (max-width: 768px) {
    .timeline.container .timeline-controls {
        margin-top: 24px;
    }
}

.timeline.container .timeline-controls .information-source {
    padding-left: 54px;
}

@media (max-width: 768px) {
    .timeline.container .timeline-controls .information-source {
        padding-left: 0;
    }
}

.timeline.container .timeline-controls .information-source .ant-typography {
    max-width: 476px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Cardo', serif;
    line-height: 158%;
    opacity: 0.9;
}

@media (max-width: 768px) {
    .timeline.container .timeline-controls .information-source .ant-typography {
        max-width: 90%;
    }
}