/*//@import 'src/main';*/

.hero-section.about {
	 margin-top: -65px;
}
 .hero-section.about h3.ant-typography {
	 margin-top: 165px;
	 margin-bottom: 118px;
	 margin-left: 104px;
	 font-family: 'Cardo';
	 font-weight: 700;
font-size: 56px;
line-height: 56px;
}
 .hero-section.about .subtitle-wrapper {
	 padding: 140px 66px 90px 66px;
	 background: #7badd3;
}
 .hero-section.about .subtitle-wrapper .subtitle {
	 font-family: 'Nunito';
font-weight: 400;
font-size: 24px;
line-height: 27px;
color: #FFFFFF;
	 margin-top: 1.5rem;
}
 .hero-section.about .image-block {
	 margin-bottom: 72px;
}
 .hero-section.about .image-block .ant-image {
	 position: relative;
	 width: 100%;
}
 .hero-section.about .image-block .ant-image img {
	 width: 100%;
	 object-fit: cover;
}
 .hero-section.about .about {
	 background: #7badd3;
}
 .hero-section.about .advantages {
	 margin-bottom: 133px;
}
 .hero-section.about .advantages .advantage-card {
	 max-width: 384px;
	 height: 270px;
	 background: rgba(8,135,189,0.03);
	 border: 1px solid rgba(8,135,189,0.08);
	 border-radius: 3px;
}
 .hero-section.about .advantages .advantage-card .ant-card-body {
	 padding: 40px;
}
 .hero-section.about .advantages .advantage-card .ant-card-body .ant-image {
	 width: 64px;
	 height: 64px;
	 margin-bottom: 24px;
}
 .hero-section.about .advantages .advantage-card .ant-card-body .ant-image::after {
	 content: none;
}
 	 .hero-section.about .advantages .ant-card-body .ant-typography.highlight {
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 34px;
color: #1B1E20;
	 }
 .hero-section.about .project-details .body1 {
	 margin-bottom: 86px;
	 margin-left: 104px;
	 margin-right: 104px;
	 font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 23px;
line-height: 36px;
color: #575C5F;
}
 .hero-section.about .image-block::after {
	 display: none;
}
 .hero-section.about .ant-image::after {
	 position: absolute;
	 top: 0;
	 right: 0;
	 width: 100%;
	 max-width: 210px;
	 height: 100%;
	 background-color: rgba(8,135,189,0.2);
	 content: '';
}
 @media (max-width: 767.98px) {
	 .containerwidth{
		 width:80% !important;
		 margin: 0 auto;
	 }
	 .hero-section.about .title-wrapper {
		 max-width: 86%;
		 margin: 0 auto;
	}
	 .hero-section.about .title-wrapper h3.ant-typography {
		 margin-top: 136px;
		 margin-bottom: 77px;
		 margin-left: 0;
		 font-size: 48px;
line-height: 53px;
	}
	 .hero-section.about .title-wrapper .subtitle-wrapper {
		 margin-bottom: 32px;
		 padding: 0;
		 background: transparent;
	}
	 .hero-section.about .title-wrapper .subtitle-wrapper .subtitle {
		 font-family: 'Nunito';
font-weight: 400;
font-size: 18px;
line-height: 27px;
color: #575C5F;
	}
	 .hero-section.about .image-block {
		 margin-bottom: 32px;
	}
	 .hero-section.about .ant-image::after {
		 content: none;
	}
	 .hero-section.about .project-details .body1 {
		 margin-bottom: 96px;
		 margin-right: 24px;
		 margin-left: 24px;
font-family: 'Nunito';
font-weight: 400;
font-size: 18px;
line-height: 27px;
color: #575C5F;
	}
	 .hero-section.about .advantages {
		 margin-bottom: 96px;
	}
	  .hero-section.about .advantages .advantage-card {
	 max-width: 100%;
	 height: 270px;
	 background: rgba(8,135,189,0.03);
	 border: 1px solid rgba(8,135,189,0.08);
	 border-radius: 3px;
}
	 .hero-section.about .advantages .ant-row .ant-card {
		 margin: 0 auto;
	}

	 .hero-section.about .advantages .ant-row .ant-card .ant-card-body {
		 padding: 24px;
	}
	 .hero-section.about .advantages .ant-card-body .ant-typography.highlight {
		 font-family: 'Nunito';
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #1B1E20;
	 }
	  .hero-section.about .image-block .ant-image img {
	 object-fit: cover;
		  height: 306px;
}
}
