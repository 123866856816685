
.banner-home {
  width: 100vw;
  height: 100vh;
  max-width: 1440px;
  min-height: 480px;
  position: relative;
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }

  @media (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.banner-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .85;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.banner-home-right {
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 600;

  @include media-breakpoint-up(lg) {
    width: 445px;
    background-color: rgba(#0887BD, .2);
  }
}

.banner-headline {
  position: absolute;
  bottom: 220px;
  left: 24px;
  width: calc(100vw - 48px);

  @include media-breakpoint-up(lg) {
    left: 48px;
    width: 480px;
    bottom: 110px;
  }

  h2 {
    font-family: $ff-secondary;
    font-size: 56px;
    color: $color-tx-01;
    font-weight: 600;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 80px;
      line-height: 80px;
      letter-spacing: .5px;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 18px;

    @include media-breakpoint-up(lg) {
      font-size: 23px;
      letter-spacing: .5px;
    }
  }
}

.banner-footer {
  position: absolute;
  height: 170px;
  width: 100%;
  bottom: 0;
  right: 0;
  background-color: #7BADD3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  .link-readmore{
    &:hover{
      color: #fff;
      &::before,&::after { background-color: #fff; }    
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 0 64px;
    height: 200px;
  }

  a {
    font-family: $ff-secondary;
    font-weight: 700;
    font-size: 15px;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;

    + a {
      margin-top: 35px;
      @include media-breakpoint-up(lg) {
        margin-top: 50px;
      }
    }

    &::before,
    &::after {
      content: '';
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      display: inline-block;
    }

    &::before {
      width: 40px;
      margin-right: 10px;
    }

    &::after {
      width: 12px;
      margin-left: 10px;
    }
  }
}

.section-home-about {
  .container {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
      padding-right: 100px;
    }

    @min-width: (min-width: 1500px) {
      padding-right: 0;
    }
  }
}

.row-home-comm {
  margin-left: -20px;
  margin-right: -20px;

  @include media-breakpoint-up(lg) {
    width: auto;
  }

  @media (min-width: 1440px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  > div {
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 1440px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.section-home-about,
.section-home-lifestyle {
  position: relative;

  .container { position: relative; }

  .row {
    margin: 0;

    > div {
      padding: 0;
      position: relative;
    }

    > .col-right {
      padding-left: 24px;
      padding-right: 24px;

      @include media-breakpoint-up(lg) {
        padding-left: 100px;
        padding-right: 0;
      }
    }

    > .col-left {

      @include media-breakpoint-up(lg) {
        padding-right: 100px;
      }
    }
  }

  .section-title {
    position: absolute;
    top: -35px;
    left: 24px;

    @include media-breakpoint-up(lg) {
      top: -60px;
      left: 48px;
    }
  }

  .section-img {
    position: relative;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      height: 100%;
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: auto;

      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 160px;
      height: 100%;
      top: 0;
      right: 0;
      background-color: rgba(#0887BD, .2);
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  .col-left,
  .col-right {
    p {
      font-size: 18px;
      margin-bottom: 16px;

      @include media-breakpoint-up(lg) {
        font-size: 23px;
        margin-bottom: 20px;
        letter-spacing: .5px;
      }

      &:last-child { margin-bottom: 0; }
    }
  }
}

.section-home-lifestyle {
  .container {
    padding-right: 24px;

    @include media-breakpoint-up(lg) {
      padding-right: 0;
    }

    @min-width: (min-width: 1500px) {
      padding-left: 0;
    }
  }

  .col-left p {
    font-size: 18px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }
}

.card-comm {
  background-color: rgba($ui-blue, .03);
  border-color: rgba($ui-blue, .08);
  height: 100%;

  .card-body {
    padding: 20px;
    font-size: 18px;

    img { border-radius: 4px; }
  }

  .card-title {
    margin: 25px 0;
    font-size: 24px;
    font-family: $ff-secondary;
    font-weight: 400;
    color: $color-tx-01;

    a,
    a:hover,
    a:focus {
      color: $color-tx-01;
    }
  }
}

.card-headline {
  border-radius: 0;
  border-width: 0;
  background-color: #7BADD3;
  text-align: center;
  padding: 50px 24px;

  h1 {
    color: #fff;
    font-size: 40px;
    font-family: $ff-secondary;

    @include media-breakpoint-up(lg) {
      font-size: 48px;
      margin-bottom: 25px;
      margin-left: auto;
      margin-right: auto;
      max-width: 50%;
    }
  }

  .link-readmore {
    justify-content: center;

    &,&:hover,&:focus { color: #fff; }

    &::before,&::after { background-color: #fff; }
  }
}

.section-home-contact {
  padding-bottom: 0;

  .section-img {
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }

    &::after {
      content: '';
      position: absolute;
      width: 160px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#0887BD, .2);
    }
  }

  .contact-panel {
    height: 100%;
    padding: 60px 24px;
    background-color: $ui-cyan;

    @include media-breakpoint-up(lg) {
      padding: 60px 60px 60px 80px;
    }
  }

  .section-subtitle {
    color: #fff;
    margin-bottom: 25px;
  }

  .form-group { margin-bottom: 25px; }

  .form-control {
    background-color: transparent;
    border-radius: 0;

    &::placeholder {
      color: rgba(#fff, .4);
    }
  }

  input.form-control {
    border-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: rgba(#fff, .8);
    padding: 0 0 10px;
  }

  textarea {
    border-color: rgba(#fff, .8);
  }

  .btn-submit {
    color: #fff;
    &::before,&::after { background-color: #fff; }
  }
}
