@mixin shadow-diffuse($color) {
  box-shadow: 0 1px 1px rgba($color, 0.08),
              0 2px 2px rgba($color, 0.12),
              0 4px 4px rgba($color, 0.16),
              0 8px 8px rgba($color, 0.20);
}

@mixin shadow-dreamy($color) {
  box-shadow: 0 1px 2px rgba($color, 0.07),
              0 2px 4px rgba($color, 0.07),
              0 4px 8px rgba($color, 0.07),
              0 8px 16px rgba($color, 0.07),
              0 16px 32px rgba($color, 0.07),
              0 32px 64px rgba($color, 0.07);
}
