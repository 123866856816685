.header-logo {
  height: 20px;
  position: absolute;
  top: 16px;
  left: 24px;
  display: block;
  z-index: 700;

  @include media-breakpoint-up(lg) {
    height: 25px;
    //top: 32px;
    left: 48px;
  }

  img {
    height: auto;
    width: 150px;
  }
}

.header-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 140px;
  height: 56px;
  background-color: #000;
  z-index: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;

  &:hover,
  &:focus { color: #fff; }

  p {
    margin-bottom: 0;
    @include transition(all 0.3s);

    &:not(:first-child) {
      opacity: 0;
      position: absolute;
      left: 32px;
    }
  }

  span {
    margin-left: 16px;
    width: 16px;
    height: 1.5px;
    background-color: #fff;
    border-radius: 2px;
    position: relative;
    @include transition(all 0.3s);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 1.5px;
      background-color: #fff;
      border-radius: 2px;
      @include transition(all 0.3s);
    }

    &::before {
      top: -5px;
      width: 25px;
    }

    &::after {
      bottom: -5px;
      width: 20px;
    }
  }
}
