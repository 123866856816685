
.banner-lifestyle {
  width: 100vw;
  height: 100vh;
  max-width: 1440px;
  max-height: 900px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;

  .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .85;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .banner-left {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
      width: 450px;
      padding-bottom: 80px;
      background-color: rgba($ui-blue, .2);
    }
  }

  .banner-title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 120px;
    background-color: $ui-cyan;
    color: #fff;
    padding: 0 24px;
    font-family: $ff-secondary;
    font-size: 40px;

    @include media-breakpoint-up(lg) {
      height: 180px;
      padding: 0 50px;
      font-size: 80px;
    }
  }
}

.card-lifestyle {
  height: 350px;
  background-color: rgba($ui-cyan, .05);
  border-color: rgba($ui-cyan, .08);

  .row {
    margin-left: -15px;
    margin-right: -15px;

    > div {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .card-body {
    height: 100%;
    padding: 25px;
    font-size: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }

  .card-img {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
