.banner-wrapper {
    position: relative;
    width: 100%;
    padding: 85px 0;
}

.banner-wrapper .banner-title {
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Cardo';
    font-weight: 700;
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    color: #FFFFFF;
}

.banner-wrapper.banner-title-tech .banner-title {
    max-width: 840px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Cardo';
    font-weight: 700;
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    color: #FFFFFF;
}

.banner-wrapper .text-center {
    line-height: 53px;
    margin-top: 20px;
    text-align: center;
}

.banner-wrapper .link {
    font-family: 'Cardo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 10px;
}

@media (max-width: 768px) {
    .banner-wrapper .banner-title {
        max-width: 90%;

        font-size: 40px;
        line-height: 44px;
    }
}

.banner-wrapper .banner-icon {
    position: absolute;
    top: 0;
    width: auto;
    height: 353px;
}

.banner-wrapper .left {
    left: 0;
}

@media (max-width: 768px) {
    .banner-wrapper .left {
        top: 0;
        width: 100%;
        height: 60%;
    }

    .banner-wrapper .left g {
        height: 100%;
        max-height: 177px;
    }

    .banner-wrapper .left g path:nth-child(3), .banner-wrapper .left g path:nth-child(4) {
        display: none;
    }
}

.banner-wrapper .right {
    right: 0;
}

@media (max-width: 768px) {
    .banner-wrapper .right {
        bottom: 0;
        width: 100%;
        height: 60%;
        margin-top: auto;
    }

    .banner-wrapper .right g {
        height: 100%;
        max-height: 177px;
    }

    .banner-wrapper .right g path:nth-child(3), .banner-wrapper .right g path:nth-child(4) {
        transform: scale(1);
    }

    .banner-wrapper .right g path:nth-child(1), .banner-wrapper .right g path:nth-child(2) {
        display: none;
    }
}

@media (max-width: 768px) {
    .banner-wrapper {
        padding: 185px 0;
    }
}

.technologies-banner .banner-title {
    max-width: 830px;
}

.ant-btn.ant-btn-primary, .link-readmore {
    display: flex;
    gap: 10px;
    align-content: center;
    align-items: center;
    padding: 0;
    font-size: 15px;
    font-family: 'Cardo', serif;
    text-transform: uppercase;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    transition: all 300ms ease-in;
}

.link-wrapper {
    position: relative;
    max-width: fit-content;
    margin: 0 auto;
    margin-top: 48px;
}

.link-wrapper .hero-link {
    margin-bottom: 0;
}

.link-wrapper .hero-link:before {
    display: none;
}

.link-wrapper .hero-link:after {
    display: none;
}

.ant-btn.ant-btn-primary:hover::before, .link-wrapper:hover::before {
    width: 12px;
}

.ant-btn.ant-btn-primary:hover::after, .link-wrapper:hover::after {
    width: 40px;
}

.ant-btn.ant-btn-primary:disabled {
    color: #cecece;
}

.ant-btn.ant-btn-primary::before, .link-wrapper::before {
    position: relative;
    display: block;
    width: 40px;
    height: 2px;
    background: #fff;
    opacity: 1;
    transition: all 300ms ease-in;
    content: '';
}

.ant-btn.ant-btn-primary::after, .link-wrapper::after {
    position: relative;
    display: block;
    width: 12px;
    height: 2px;
    background: #fff;
    opacity: 1;
    transition: all 300ms ease-in;
    content: '';
}